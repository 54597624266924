@import './vars.css';

.contact-page, .contact-get-in-touch{
    padding-top: 60px;
}

.contact-title{
    font-size: 35px;
    font-weight: 500;
    font-family: "Poppins", Sans-serif;
    color: var(--global-color-primary);
}

.contact-sub-title{
    color: var(--global-color-text);
    font-family: "Poppins", Sans-serif;
    font-size: 25px;
    font-weight: 300;
}

.contact-get-in-touch{
    background-image: linear-gradient(90deg, var(--global-color-primary) 0%, #03121C 100%);
}

.contact-get-in-touch .contact-title{
    color: #f9cb31;
    font-family: "Poppins", Sans-serif;
    font-size: 35px;
    font-weight: 500;
    line-height: 45px;
}

.contact-get-in-touch .contact-sub-title {
    color: #fff;
    font-size: 13px;
    line-height: 1.7;
}

.widgets-section .widget-heading h2 {
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.widget-content p {
    color: #fff;
    font-size: 1em;
    line-height: 1.7;
}   

.widgets-section{
    border-bottom: 1px solid var(--global-color-text);
    padding: 10px 0;
    font-weight: 500;
}

.widgets-section:last-child {
    border-bottom: 0;   
}

.widget-content ul {
    padding: 0;
    list-style: none;
    display: flex;
}

.widget-content ul li {
    margin-right: 15px;
}

.widget-content ul li a {
    border-radius: 100px 100px 100px 100px;
    display: block;
    background-color: #fff;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
}

.widget-content ul li a i{
    color: var(--global-color-primary);
}
.contact-detail{
    padding: 0px 100px 0px 100px;
}

.contact-page #contact-form .form-group{
    margin-bottom: 20px;
}

#contact-form .form-control {
    border: 0;
    border-bottom: 1px solid #9f9f9f;
    border-radius: 0;
    appearance: auto;
    -webkit-appearance: auto;
    -moz-appearance: inherit;
}
#contact-form .form-control:focus {
    box-shadow: none;
}
input.iti__tel-input:focus-visible {
    border: 0;
    outline: none;
}
.iti--allow-dropdown input.iti__tel-input{
    border: 0;
    width: 100%;
}
#contact-form .form-control.tel-input{
    padding-left: 0;
}