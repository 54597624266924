:root {

    --global-color-primary: #052237;
    --global-color-secondary: #00A4E0;
    --global-color-text: #748394;
    --global-color-accent: #F9CB31;
    --global-color-white: #FFFFFF;
    --global-color-darkgray: #2E2E2E;
    --global-typography-primary-font-family: "Poppins";
    --global-typography-primary-font-weight: 600;
    --global-typography-secondary-font-family: "Poppins";
    --global-typography-secondary-font-weight: 400;
    --global-typography-text-font-family: "Poppins";
    --global-typography-text-font-weight: 400;
    --global-typography-accent-font-family: "Poppins";
    --global-typography-accent-font-weight: 500;
}