@import './vars.css';

body{
    transition-delay: 250ms;
}
.btn-primary{
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-transform: none;
    background-color: var(--global-color-primary);
    border-radius: 90px 90px 90px 90px;
    padding: 7.5px 23px;
    border: none;
}

.btn-primary:hover{
    background-color: var(--global-color-primary);
}

.btn-secondary{
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-transform: none;
    background-color: var(--global-color-secondary);
    border-radius: 90px 90px 90px 90px;
    padding: 7.5px 23px;
    border: none;
}

.btn-secondary:hover{
    background-color: var(--global-color-secondary);
}


.top-bar-right ul li a{
    font-size: 13px;
    color: #fff;
}
a{
    text-decoration: none;
}


body{
    font-family: 'Poppins', sans-serif;
}

p{
    color: var(--global-color-primary);
    font-size: 13px;
    line-height: 21px;
    font-weight: 300;
}

p strong{
    color: #052237;
}
.text-align-end{
    text-align: end;
}
.text-white strong{
    color: var(--global-color-white) !important;
    font-weight: 800;
}


.my-80{
    margin-top: 80px;
    margin-bottom: 80px;
}

.py-80{
    padding-top: 80px;
    padding-bottom: 80px;
}

.pt-80{
    padding-top: 80px;
}

.pt-50{
    padding-top: 50px;
}

.pb-80{
    padding-bottom: 80px;
}

.dealer-address div{
    color: var(--global-color-text);
    line-height: 36px;
}

.dealer-address a{
    color: var(--global-color-text);
}

.dealer-address h2{
    font-size: 20px;
    color: var(--global-color-white);
}

.dealer-address .dealer-email{
    color:var(--global-color-white);
    cursor: pointer;
}

.total-control{
    font-size: 30px !important;
    font-weight: 500 !important;
}


.gallary-title{
    padding-bottom:25px;
}

.form-select{
    background-image: none !important;
}
.haq-right select {
    -webkit-appearance: listbox !important;
}

.home-banner-section .banner-content .banner-title h1{
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    font-weight: 700;
    line-height: 49px;
    color: var(--global-color-white);
    max-width: 600px;
}

.home-banner-section .banner-content .banner-title h1 span{
    color: var(--global-color-accent);
}
.dealer-banner-section .banner-content .banner-title h1{
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: var(--global-color-white);
    max-width: 600px;
}

.dealer-banner-section .banner-content .banner-title h1 span{
    color: var(--global-color-accent);
    font-size: 24px;
    font-weight: 700 !important;
    text-transform: uppercase;
    line-height: 36px !important;
    margin-bottom: 10px;
    display: block;
}


.unmatched{
    display: none;
}

.toast-body{
    color: var(--global-color-text);
}

#contact-form ::placeholder {
    color: var(--global-color-text) !important;
    opacity: 0.4 !important;
}

#contact-form .default-selection{
    color: var(--global-color-text) !important;
    opacity: 0.4 !important;
}

#contact-form ::-ms-input-placeholder {
    color: var(--global-color-text) !important;
    opacity: 0.4 !important;
}

.haq-right ::placeholder {
    color: var(--global-color-primary) !important;
    opacity: 0.6 !important;
}

.haq-right ::-ms-input-placeholder {
    color: var(--global-color-primary) !important;
    opacity: 0.6 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.thank-you-container{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.thank-you-container .fa-check-circle{
    font-size: 40px !important;
    padding-bottom: 30px;
    color: green;
}

.privacy-policy-text h5{
    margin-top: 20px;
}

.privacy-policy-text h5:after{
    content: "";
    display: block;
   width: 25px;
    height: 3px;
    margin: 10px 0;
    background: #6c6c6c;
}

        
@media (max-width: 1024px){
    .home-banner-section .banner-content{
        width: 60% !important;
    }

    .dealer-banner-section .banner-content{
        position: absolute !important;
        top: 10% !important;
        width: 60% !important;
    }

    .home-banner-section{
        height: calc(100vh - 130px)!important
    }

    .home-banner-overlay{
        width:100% !important;
        max-width:100% !important;
        transform:unset !important;
        left:0 !important;
    }

    .banner-left-form{
        max-width: unset !important;
        margin-right:10px;
    }

    .unmatch-list{
        width: 40% !important;
    }
    .unmatch-slider{
        width: 60% !important;
    }
    
}

@media (max-width: 991px){
    .home-banner-section .banner-content{
        width: 70% !important;
    }

    .dealer-banner-section .banner-content{
        width: 70% !important;
    }
}

@media (max-width: 820px){
    .above-footer-row{
        background-image: linear-gradient(180deg, var(--global-color-accent) 50%, var(--global-color-secondary) 49%) !important;
    }
    .see-our-product{
        margin: 15px auto 15px !important;
    }
    .unmatch-list{
        width: 40% !important;
    }
    .unmatch-slider{
        width: 60% !important;
    }
    
}

@media (max-width: 768px){
    .contact-form-submit{
        flex-direction: column;
    }
    .home-banner-section .banner-content{
        width: 92% !important;
    }

    .dealer-banner-section .banner-content{
        position: absolute !important;
        top: 10% !important;
        width: 92% !important;
    }

    .home-banner-overlay{
        width:100% !important;
        max-width:100% !important;
        transform:unset !important;
        left:0 !important;
    }

    .banner-left-form{
        max-width: unset !important;
        margin-right:10px;
    }

    .about-left{
        padding-top:30px !important;
        padding-bottom: 30px !important;
    }

    .navbar-collapse{
        text-align: center !important;
    }
    .footer-social{justify-content:end !important;}
    .unmatched{
        margin-bottom: 10px;
        text-align: center;
        display: block !important;
    }
    .unmatched .swiper-button-prev, .unmatched .swiper-button-next{
        margin:10px;
        height:20px !important;        
        width:20px !important;      
        border-radius: 20px !important;  
    }
    .unmatched .swiper-button-prev::after, .unmatched .swiper-button-next::after{
        font-size: 13px !important;
    }
    .unmatch-list{
        width:40% !important;
    }
    .unmatch-slider{
        width:60% !important;
    }
    .above-footer-row{
        background-image: linear-gradient(180deg, var(--global-color-accent) 50%, var(--global-color-secondary) 49%) !important;
    }
    .dealer-address, .footer-logo{
        padding-top: 20px !important;
    }
    .dealer-address{
        text-align: left !important;
    }

    .banner-content img{
        width: 100px!important;
    }
    
}

@media (max-width: 767px){

    .home-banner-section .banner-content{
        width: unset !important;
        top: 8%
    }
}

@media (max-width: 540px){
    .home-banner-section .banner-content{
        width: unset !important;
    }
    .dealer-banner-section .banner-content{
        position: absolute !important;
        top: 1% !important;
        width: unset !important;
    }
    .estimate-form-submit{
        flex-direction: column-reverse;
    }

    .home-banner-overlay{
        width:100% !important;
        max-width:100% !important;
        transform:unset !important;
        left:0 !important;
    }

    .banner-left-form{
        max-width: unset !important;
        margin-right:10px;
    }


    .about-left{
        padding-top:30px !important;
        padding-bottom: 30px !important;
    }

    .footer-main,.footer-logo,.footer-social,.navbar-collapse,.text-end{
        text-align: center !important;
    }
    .footer-social{justify-content:center !important;}
    .unmatched{
        margin-bottom: 10px;
        text-align: center;
        display: block !important;
    }
    .unmatched .swiper-button-prev, .unmatched .swiper-button-next{
        margin:10px;
        height:20px !important;        
        width:20px !important;      
        border-radius: 20px !important;  
    }
    .unmatched .swiper-button-prev::after, .unmatched .swiper-button-next::after{
        font-size: 13px !important;
    }
    .unmatch-list{
        width:60% !important;
    }
    .unmatch-slider{
        width:40% !important;
    }
    .above-footer-row{
        background-image: linear-gradient(180deg, var(--global-color-accent) 50%, var(--global-color-secondary) 49%) !important;
    }
    .dealer-address, .footer-logo{
        padding-top: 20px !important;
    }
    .dealer-address{
        text-align: left !important;
    }

    .banner-left-form .form-group{
        margin-bottom: 6px;
    }

    .home-banner-section .banner-content .banner-title h1{
        font-family: "Poppins", Sans-serif;
        font-size: 30px !important;
        font-weight: 600 !important;
        line-height: 35px !important;
        color: var(--global-color-white);
        max-width: unset !important;
    }
    
    .home-banner-section .banner-content .banner-title h1 span{
        color: var(--global-color-accent);
    }

    .dealer-banner-section .banner-content .banner-title h1{
        font-family: "Poppins", Sans-serif;
        font-size: 13px !important;
        font-weight: 400 !important;
        line-height: 21px !important;
        color: var(--global-color-white);
        max-width: unset !important;
    }
    
    .dealer-banner-section .banner-content .banner-title h1 span{
        color: var(--global-color-accent);
        font-size: 14px !important;
        font-weight: 700 !important;
        text-transform: uppercase;
        line-height: 21px !important;
        margin-bottom: 10px;
        display: block;
    }

    .home-banner-section{
        max-height: inherit!important;
    }

    /* .dealer-banner-section{
        max-height: 560px!important;
    } */
    
}

@media (max-width: 435px){
    .unmatch-list{
        width:70% !important;
    }
    .unmatch-slider{
        width:30% !important;
    }
    .unmatched{   
        display: block !important;
    }

    .dealer-banner-section{
        min-height: 620px;
    }

    .dealer-banner-section .home-banner-overlay{
        height: 100%;
    }    

    .dealer-banner-section .banner-content .banner-title h1{
        font-family: "Poppins", Sans-serif;
        font-size: 20px !important;
        font-weight: 400 !important;
        line-height: 26px !important;
        color: var(--global-color-white);
        max-width: unset !important;
    }
    
    .dealer-banner-section .banner-content .banner-title h1 span{
        color: var(--global-color-accent);
        font-size: 25px !important;
        font-weight: 700 !important;
        text-transform: uppercase;
        line-height: 34px !important;
        margin-bottom: 20px;
        display: block;
    }

    .dealer-banner-section .banner-left-form{
        margin-top: 2rem!important;
        padding: 10px 20px 3px;
    }

    .dealer-banner-section .banner-left-form button{
        margin-top: 3px;
    }

    .banner-left-form input, .banner-left-form select{
        height: 40px;
    }

    .banner-left-form h3{
        font-size: 20px!important;
        font-weight: 700;
    }

    .banner-content img{
        width: 160px!important;
    }

    .banner-content .bbb-logo{
        width: 220px!important;
    }

    .home-about{
        margin-top: 30px!important;
    }
    .product-section{
        margin-bottom: 30px!important;
    }

    .customization-sec{
        padding-top: 40px!important;
    }
    
}

@media (max-width: 425px){
    #top-bar{
        text-align: center !important;
    }
    .top-bar-right{
        display: flex !important;
        justify-content: center;

    }
    .home-banner-section .banner-content{
        width: unset !important;
    }

    .dealer-banner-section .banner-content{
        position: absolute !important;
        top: 1% !important;
        width: unset !important;
    }

    .home-banner-overlay{
        width:100% !important;
        max-width:100% !important;
        transform:unset !important;
        left:0 !important;
    }

    .banner-left-form{
        max-width: unset !important;
        margin-right:10px;
    }

    .swatch-imgs img{
        margin-top:10px;
        margin-bottom:10px;
    }
    .unmatched{
        margin-bottom: 10px;
        text-align: center;
        display: block !important;
    }
    .unmatched .swiper-button-prev, .unmatched .swiper-button-next{
        margin:10px;
        height:20px !important;        
        width:20px !important;      
        border-radius: 20px !important;  
    }
    .unmatched .swiper-button-prev::after, .unmatched .swiper-button-next::after{
        font-size: 13px !important;
    }

    .about-left{
        padding-top:30px !important;
        padding-bottom: 30px !important;
    }
    .unmatch-list ul{
        padding: 0 !important;
    }
    .unmatch-list li{
        padding:15px 0px 15px 6px !important;
    }

    .unmatch-list{
        width:60% !important;
    }
    .unmatch-slider{
        width:40% !important;
    }

    .haq-left h2{
        font-size: 30px !important;
        line-height: 40px !important;
    }
    .haq-left p{
        font-size: 14px !important;
    }
    .see-our-product{
        margin: 15px auto 15px !important;
    }
    .above-footer-row{
        background-image: linear-gradient(180deg, var(--global-color-accent) 50%, var(--global-color-secondary) 49%) !important;
    }
    .dealer-address{
        padding-top: 20px !important;
    }
    #top-bar{
        height: unset !important;
    }
    .section-btn{
        text-align: center !important;
    }
    .find-location-ul{
        margin-bottom: 0 !important;
    }
    .find-location-li{
        margin-left: 0 !important;
    }

    .footer-logo{
        padding-top: 40px !important;
    }

    .footer-main,.footer-logo,.footer-social{
        text-align: left !important;
    }

    p{
        font-size: 14px;
        line-height: 23px;
    }

    .wf-col p{
        font-size: 13px!important;
    }

    .get-in-touch p, .see-product-text p, footer ul li div a{
        font-size: 14px!important;
    }
    
}

@media (max-width: 400px){
    .dealer-banner-section{
        min-height: 600px;
    }    

    .dealer-banner-section .banner-left-form{
        margin-top: 1rem!important;
        padding: 10px 20px 3px;
    }

    .dealer-banner-section .banner-content .banner-title h1{
        font-size: 18px !important;
        line-height: 22px !important;
    }
    
    .dealer-banner-section .banner-content .banner-title h1 span{
        font-size: 23px !important;
        line-height: 29px !important;
    }

    .banner-left-form h3{
        font-size: 20px!important;
    }

    .banner-content img{
        width: 140px!important;
    }

    .banner-content .bbb-logo{
        width: 220px!important;
    }

    .banner-left-form input, .banner-left-form select{
        padding: 0px 10px!important;
        height: 28px!important;
    }
}

@media (max-width: 375px){
    .dealer-banner-section{
        min-height: 600px;
    }

    .dealer-banner-section .home-banner-overlay{
        height: 100%;
    }    

    .dealer-banner-section .banner-content .banner-title h1{
        font-size: 18px !important;
        line-height: 23px !important;
    }
    
    .dealer-banner-section .banner-content .banner-title h1 span{
        font-size: 22px !important;
        line-height: 26px !important;
    }

    .banner-left-form h3{
        font-size: 20px!important;
        font-weight: 700;
    }

    .banner-content img{
        width: 135px!important;
    }
    .banner-content .bbb-logo {
        width: 190px !important;
    }

}

@media (max-width: 360px){
    .dealer-banner-section{
        min-height: 570px;
    }

    .dealer-banner-section .home-banner-overlay{
        height: 100%;
    }    

    .dealer-banner-section .banner-content .banner-title h1{
        font-size: 17px !important;
        line-height: 21px !important;
    }
    
    .dealer-banner-section .banner-content .banner-title h1 span{
        font-size: 20px !important;
        line-height: 25px !important;
    }

    .banner-left-form h3{
        font-size: 18px!important;
        font-weight: 700;
    }

    .banner-content img{
        width: 120px!important;
    }
    .banner-content .bbb-logo {
        width: 180px !important;
    }

}

@media (max-width: 320px){
    .dealer-banner-section{
        min-height: 550px;
    }

    .dealer-banner-section .home-banner-overlay{
        height: 100%;
    }    

    .dealer-banner-section .banner-content .banner-title h1{
        font-size: 16px !important;
        line-height: 21px !important;
    }
    
    .dealer-banner-section .banner-content .banner-title h1 span{
        font-size: 18px !important;
        line-height: 22px !important;
    }

    .banner-left-form h3{
        font-size: 17px!important;
        font-weight: 700;
    }

    .banner-content img{
        width: 90px!important;
    }

    .banner-left-form input, .banner-left-form select{
        padding: 3px 10px!important;
        height: 30px!important;
    }
}