@import './vars.css';

.commcial-heading{
    text-align: center;
    font-size:45px ;
    font-weight: 600;
    font-family: "Poppins", Sans-serif;

}
.centered-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 10px;
}

.header-section{
    margin: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
}
.hero-para p{
    text-align: center;
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 300;
    width: 55%;
    max-width: 55%;
    margin: 0 auto; /* Center the paragraph horizontally */

}

.swiper-button-prev, .swiper-button-next {
    width: 40px !important;
    height: 40px !important;
    background-image: none;
    outline: none;
    font-size: 22px;
    background-color: #FCFBFA;
    color: #AEAEAE;
    border-radius: 100% 100% 100% 100%;
    position: unset !important;
    display: inline-flex !important;
}
.swiper-button-next, .swiper-button-prev{
    border: 2px solid var(--global-color-text) !important;
    border-radius: 0 !important;
    width: 40px!important;
    height: 40px!important;
    margin-bottom: 20px;
    background: none !important;
}

.swiper-button-prev{
    margin-right: 10px;
}

.commercial-page .swiper{
    /* overflow: visible!important; */
    margin-top:12px !important;
}

.swiper-slide img{
    /* border-radius: 30px; */
    max-height: 570px;
    width: 100%;
    object-fit: cover;
}
/* .table-swiper>.swiper-wrapper>.swiper-slide{
    width: 187px !important;
}
.table-swiper>.swiper-wrapper>.swiper-slide{
    border-radius: 30px;
} */

/* .swiper-slide{
    width: 338.25px;
} */

.swiper-initialized{
    display: flex;
    flex-direction: column-reverse;
    align-items: end;
}

.swiper-button-next:after, .swiper-button-prev:after{
    font-size: 22px!important;
    color: var(--global-color-text);;
 
    
   font-weight: 900;
}

.para{
    text-align: center;
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 300;
    width: 55%;
    max-width: 55%;
}
.trusted-title h2{
    color: var(--e-global-color-179023b);
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 300;
}
.trusted-content{
    text-align: center;
}
.testimonial-section{
    padding:0px 0px 0px 0px;
    width: 100%;

}
.slider{
    margin:30px 0px 60px 0px
}
.testimonial-logos{
    margin:20px 0px 0px 0px

}
.warranty-section{
        width: 90%;
        margin-left: 10%; 
        background-color: var(--global-color-secondary) !important;
        padding: 20px; 
        height: 441px;
    z-index: 1;
    position: relative;
    bottom: -103px;
       

}
.warranty-img{
    position: relative;
    bottom:23%;
    left:133px;
    width: 90%;
}
.warranty-heading{
    margin: 0px 0px 0px 30px;
    width:27%;
}
.warranty-heading-title{
    color: var(--global-color-white);
    font-family: "Poppins", Sans-serif;
    font-size: 35px;
    font-weight: 500;
}
.warranty-para{
    width:56.438%;
    
}
.warranty-para-title{
    color: var(--global-color-white);
    font-family: "Poppins", Sans-serif;
    font-size: 12px;
    font-weight: 400;
}
.icon-box-title{
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: var(--global-color-white);

}
.icon-box-description{
    color: var(--global-color-white);
    font-family: "Poppins", Sans-serif;
    font-size: 12px;
    font-weight: 400;
    
}
.warranty-block{
    margin: 0px 0px 0px 30px;

}

.product-offering{
    width: 100%;
    background-color: #000715;
    padding: 20px; 
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    

}
.product-offering-bg {
    max-width: 600px; 
    padding: 120px 0 0 0 ; 
  }
  
  .product-offering-heading {
    text-align: center;
    font-family: "Poppins", Sans-serif;
    font-size: 35px;
    font-weight: 500;
    color: var(--global-color-white);
  }
  
  .product-offering-content p {
    text-align: center;
    font-family: "Poppins", Sans-serif;
    font-size: 13px;
    font-weight: 300;
    color: var(--global-color-white);

  }
  .product{
    width: 100%;

    
  }
  .product-img1 {
    background-image: url(http://fenetex.pisego.com/wp-content/uploads/2023/11/insect-commercial.jpg);
    background-size: cover;
    background-position: 50%;
   width: 100%;
   height: 500px;
   transition-duration:1500ms;
}
.product-img2 {
    background-image: url(http://fenetex.pisego.com/wp-content/uploads/2023/11/clear-commercial.jpg);
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: 15%;
}
.product-img3 {
    background-image: url(http://fenetex.pisego.com/wp-content/uploads/2023/11/shade-commercial.jpg);
    width: 100%;
   height: 500px;
   background-size: cover;
    background-position: 15%;
}
.product-img4{
    background-image: url(http://fenetex.pisego.com/wp-content/uploads/2023/11/hurricane-commercial.jpg);
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: 15%;
}
.product-img{
    max-height: 500px;
    position: relative;
    flex-wrap: wrap;
    min-height: 500px;
    z-index: 1;
    overflow: hidden;


}
.product-img h2{
    position: absolute;
    bottom: 28px;
    transform: skewy(10deg);
}
.overlay-section{
    position: absolute;
    bottom: -38px;
    height: auto;
    transform: skewY(-10deg);
    padding: 37px 30px 100px;
    z-index: 1;
  
}
.overlay-section-title{
    font-family: "Poppins", Sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: var(--global-color-accent);
    margin-bottom: 42px;;

}
.design {
    display: flex;
    align-items: center;
}

.design-section{
    background-color: #000715;

}

.design-img {
    background-image: url(http://fenetex.pisego.com/wp-content/uploads/2023/11/bg_manufacture.jpg);
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover; 
    height: 670px;
    flex: 1;
}

.design-content {
    flex: 1; 
    padding: 73px; 
    color: var(--global-color-white); 
    max-width: 1200px;
    width: 50%;
}
.design-content h2{
    color: var(--e-global-color-white);
    font-family: "Poppins", Sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    max-width: 575px;;
}
.technology{
    max-width: 900px;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    padding: 100px 0px 100px 0px;
}
.tech-content{
    width:50%;
}
.tech-sub-heading{
    color: var(--global-color-white);
    font-family: "Poppins", Sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 35px;
}
.tech-main-content{
    color: var(--global-color-white) !important;
    font-family: "Poppins", Sans-serif;
    font-size: 13px;
    font-weight: 300;
}
.tech-main-content-para p{
    color:var(--global-color-white) !important;
}

.tech-heading-title{
    color: var(--global-color-white);
    font-family: "Poppins", Sans-serif;
    font-size: 9px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.have-project{
    background-color:var(--global-color-accent);
    padding: 50px 20px 50px 20px;
}
.have-project-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
}

.have-project-para p {
    text-align: center;
    color: var(--global-color-primary);
    font-family: "Poppins", Sans-serif;
    font-size: 13px;
    font-weight: 300;
}
.have-project-heading h2{
    margin-bottom: 30px;
    line-height: 1.2;
    font-weight: bold;
    font-size: 35px;
}
.have-project-button a{
    font-family: "Poppins", Sans-serif;
    font-weight: 500;
    fill:  var(--global-color-white);;
    color:  var(--global-color-white);
    background-color: var(--global-color-primary);
    border-radius: 40px 40px 40px 40px;
    display: inline-block;
    line-height: 1;
    font-size: 15px;
    padding: 12px 24px;
    color: #fff;
    fill: #fff;
    text-align: center;
   
    transition: all .3s;
}

.overlay-description{
    transform: skewY(10deg);
    color: var(--global-color-primary);
    font-family: var(--e-global-typography-text-font-family), Sans-serif;
    font-weight: var(--e-global-typography-text-font-weight);
    margin-bottom: 15px ;
    border-color: #55595c;
    fill: #55595c;
    width: 100%;
    margin: 0;
    position: relative;
    opacity: 0;
}
.product-img:hover .overlay-section{
   
    background-color:var(--global-color-accent);
    transition: background .5s,border .5s,border-radius .5s,box-shadow .5s,-webkit-border-radius .5s,-webkit-box-shadow .5s;



   

}
.product-img:hover .overlay-description{
    opacity: 1;

}
.product-img:hover .overlay-section-title{
    color:#55595c

}
.product-img:hover .overlay-button-anchor{
    color:#55595c

}
.overlay-button{
    right: 10px;
    width: 90%;
    left: 10px;
    text-align: right;
    position: absolute;
    bottom: 28px;
    transform: skewy(10deg);
    color: #55595c;
    border-color: #55595c;
    fill: #55595c;
    margin-bottom: 20px;;

}
.overlay-button-anchor{
    font-size: 20px;
    padding: 24px 0;
    cursor: pointer;
    -ms-flex-item-align: center;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    background: transparent;
    right: 10px;
    color:var(--global-color-accent);
    width: 90%;


}

.warranty-img-mobile{
    display: none;
}
.p-mobile-view{
    display: none;
}

.product-desktop-view{
    display: flex;
}
.first-warranty-block{
    width: 30.212%;
}
.second-warranty-block{
    width: 28.788%;
}
.third-warranty-block{
    width: 29%;
}
.mobile-view{
    width: 200px;
}

@media (max-width: 991px){
    .warranty-img{
        display: none;
    }
    .warranty-img-mobile{
       display: flex;
       flex-direction: column;
       position: relative;
       top: -75px;
    }
    .warranty-para{
        width:100%;
        
    }

    .warranty-section{
        width: 100%;
        margin-left: 0%; 
        padding: 10px; 
        height: auto;
        background-color: var(--global-color-secondary) !important;


    }
    .warranty-heading{
        width: 100%;
        margin: 0px 0px 0px 10px;       
    }

    .warranty-section{
        bottom: 0px;
    }

    .testimonial-section{
        padding-bottom: 80px;
    }

    .product-offering-bg{
        padding: 60px 0px 50px 0px;
    }
}

@media screen and (max-width: 768px) {
    .commcial-heading{
     font-size: 32px; 
    }
    .hero-para p{      
        width: 90%;
        max-width: 90%;    
    }

    .product-mobile-view .swiper-button-next, .product-mobile-view .swiper-button-prev{
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .product-mobile-view .centered-container{
        margin-bottom: 15px;
    }
    
    
    
    .product-offering{
        width: 100%;
        background-color: #000000;
        padding: 20px; 
        height: 500px;
        z-index: 1;
        bottom: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        
    
    }
    .icon-box-content {
        transition: height 0.35s ease;
      }

      .product-offering-heading {
        text-align: start;
      
      }
      
      .product-offering-content p {
        text-align: start;
     
    
      }
      .product-offering-bg {
        max-width: 600px; 
        /* padding: 30px 0 0 0 ;  */
      }
      .product-offering{
        width: 100%;
        background-color: #000715;
        padding: 20px; 
        height: auto;
        z-index: -1;
        bottom: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        
    
    }
    .design {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        width: 100%;
    }

    .design-content {
        flex: 0; 
        padding: 20px; 
        color: var(--global-color-white); 
        width: 100%;
      

    }
    .design-content h2{
        color: var(--e-global-color-white);
        font-family: "Poppins", Sans-serif;
        font-size: 28px;
        font-weight: 600;
        line-height: 30px;
        width: 100%;;
    }
    .p-mobile-view{
        display: block;
        padding: 18px;
        background: #000000;
    }
    .p-desktop-view{
        display: none;
    }

    .overlay-mobile-description{
        color: var(--global-color-white);
        font-family: var(--e-global-typography-text-font-family), Sans-serif;
        font-weight: var(--e-global-typography-text-font-weight);
       
        
        
    }
    .overlay-section-mobile{
      
        position: absolute;
        bottom: -38px;
        height: auto;
        padding: 37px 30px 100px
      
    }
    .product-mobile-img{
        max-height: 500px;
        position: relative;
        flex-wrap: wrap;
        min-height: 500px;
        z-index: 1;
        overflow: hidden;
    
    
    }
    .overlay-section-mobile-title{
        font-family: "Poppins", Sans-serif;
        font-size: 20px;
        font-weight: 500;
        color: var(--global-color-white);
        margin-bottom: 42px;;
    
    }
    .product-swiper{
        margin-top: 0px !important;
        background-color:  #000715;
    }
    
    .swiper-slide-bg{
        background-image: url(http://fenetex.pisego.com/wp-content/uploads/2023/11/insect-commercial.jpg);
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 100%;

    }
    .swiper-slide-bg-2{
        background-image: url(http://fenetex.pisego.com/wp-content/uploads/2023/11/clear-commercial.jpg);
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 100%;

    }
    .swiper-slide-bg-3{
        background-image: url(http://fenetex.pisego.com/wp-content/uploads/2023/11/shade-commercial.jpg);
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 100%;

    }
    .swiper-slide-bg-4{
        background-image: url(http://fenetex.pisego.com/wp-content/uploads/2023/11/hurricane-commercial.jpg);
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        min-width: 100%;
        min-height: 100%;

    }
    .custom-slide-content{
        height: 400px;
    }
    .slide-heading{
        font-size: 23px;
        line-height: 1;
        font-weight: 700;
        font-family: var(--e-global-typography-primary-font-family), Sans-serif;
        color: var(--global-color-white);
    }
    .slide-heading:not(:last-child){
        margin-bottom:30px;
    }
    .slide-description:not(:last-child) {
        color: var(--global-color-white);
        margin-bottom: 30px;
        font-size: 13px;
        line-height: 1.4;
        font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
        font-weight: var(--e-global-typography-secondary-font-weight);
    }
    .swiper-overlay-bg{
        background-color: #000000A8;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
    }
    .swiper-slide-inner{
        padding: 20px 20px 20px 20px;
        text-align: left;
        background-repeat: no-repeat;
        background-position: 50%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        display: flex;
        transition:all .35s;
        align-items: center;

    }
    .swiper-slide-contents{
        max-width: 98%;

    }
    .slide-button{
        

    display: inline-block;
    line-height: 1;
    background-color: #818a91;
    font-size: 15px;
    padding: 12px 24px;
    border: 3px solid var(--global-color-accent);
    text-align: center;
    transition: all .3s;
    background: transparent;
    text-decoration: none;
    color:var(--global-color-accent);
    border-color: var(--global-color-accent);
}
.warranty-block{
    margin: 0px 0px 0px 0px;

}

.design-mobile-img {
    background-image: url(http://fenetex.pisego.com/wp-content/uploads/2023/11/bg_manufacture.jpg);
    background-position: bottom center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    height: 359px;
    width: 100%;
  
}
.technology{
    max-width: 900px;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    padding: 50px 20px 0px 20px
    ;
}
.tech-content{
    width:100%;
    padding: 10px;
}
.tech-main-content-para{
    color: var(--global-color-white) !important;
    font-family: "Poppins", Sans-serif;
    font-size: 13px;
    font-weight: 300;
}
.mobile-view{
    max-width: 300px;
}

.swiper-slide-contents {
    opacity: 0;
    transform: translateY(100px); 
    transition: opacity 1.25s ease, transform 1.25s ease; 
  }
  
  .swiper-slide-active .swiper-slide-contents {
    opacity: 1;
    transform: translateY(0); 

    

   
    
  }

}

.commercial-slider .swiper-btn-prev{
    margin-right: 40px;
}
