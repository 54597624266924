@import './vars.css';

#top-bar {
    background: var(--global-color-secondary);
    padding: 2px 0;
    height: 34px;
    position: relative;
}

#top-bar .top-bar-right {
    z-index: 999;
}

.topbar-btn{
    color: #fff;
    font-size: 13px;
}

.top-bar-right ul{
    float: right;
    padding-left: 0px;
}

.top-bar-right ul li{
    float: left;
    margin-left: 20px;
    list-style-type: none;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    height: 30px;
}


.find-location-ul{
    position: relative;
}

.find-location{
    margin-left: 10px;
}


.find-location-li{
    display: flex;
}

.zipcode-field{
/*    display: none;*/
    position: relative;
    width: 100%;
    transition: 2s;
    overflow: hidden;
}

.zipcode-field .input-group-append{
    position: absolute;
    right: 10px;
    z-index: 3;
    top: 4px;
}

.zipcode-field .input-group-append i{
    color: #0177c1;
}

.find-location-ul.show .zipcode-field{
    display: flex;
    z-index: 999;
    width: 100%;
    transition: 2s;
}

.find-location-ul.show .zipcode-field input{
    font-size: 12px!important;
    max-width: 150px;
}

.find-location-ul .zipcode-field input{
    padding: 4px 30px 4px 13px;
    color: #000;
    background: #dfdfdf!important;
    max-width: 150px;
    border: none;
}

.find-location-ul.show .zipcode-field .btn{
    background: #00aeef;
    border-color: #00aeef;
    color: #fff;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.lifetime-warranty p{
    color: #ffffff;
}

.logo p{
    font-size: 11px;
    color: #fff;
    border-top: 1px solid #fff;
    padding-top: 2px;
    margin-top: 7px;
    margin-bottom: 0px;
    padding-left: 23px;
}

.main-header{
    width: 100% !important;
    background-color: var(--global-color-primary);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    transition: all 0.5s ease;
    min-height: 70px;
}

.fixed {
    position: fixed;
    top:0; left:0;
    width: 100%;
    z-index: 9; 
    animation: smoothScroll 1s forwards;
}
@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

.logo span{
    color: var(--global-color-accent);
}

.navbar-nav .nav-link.active{
    color: #fff;
    border-bottom: 1px solid #fff;
}

.navbar-nav .nav-link{
    font-family: "Poppins", Sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
    text-transform: uppercase;
    padding: 0px!important;
}

.navbar-nav .nav-item {
    padding-right: 0.8rem!important;
    padding-left: 0.8rem!important;
    padding-top: 4px;
    padding-bottom: 4px;
}

.btn-primary{
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-transform: none;
    background-color: var(--global-color-primary);
    border-radius: 90px 90px 90px 90px;
    padding: 7.5px 23px;
    border: none;
}

.btn-primary:hover{
    background-color: var(--global-color-primary);
}

.btn-secondary{
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-transform: none;
    background-color: var(--global-color-secondary);
    border-radius: 90px 90px 90px 90px;
    padding: 7.5px 23px;
    border: none;
}

.btn-secondary:hover{
    background-color: var(--global-color-secondary);
}

.btn-accent{
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 600;
    text-transform: none;
    color: var(--global-color-78ad583);
    background-color: var(--global-color-accent);
    border-radius: 90px 90px 90px 90px;
    padding: 7.5px 23px;
    border: none;
}

.btn-accent:hover{
    background-color: var(--global-color-accent);
}

.btn-accent-2{
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 600;
    text-transform: none;
    color: var(--global-color-accent);
    border-color: var(--global-color-accent);
    border-radius: 90px 90px 90px 90px;
    padding: 7.5px 23px;
}

.btn-accent-2:hover{
    border-color: var(--global-color-accent);
    color: var(--global-color-accent);
}

.banner-section {
    position: relative;
    height: 100vh;
    max-height: 700px;
}

.banner-section .banner-bg{
    height: 100%;
    overflow: hidden;
    position: absolute;
    width: 100%;
}

.banner-section .banner-bg .banner-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner-section .banner-content{
    position: absolute;
    top: 42%;
    width: 33%;
    transform: translateY(-50%);
}

#contact-form .form-control {
    border: 0;
    border-bottom: 1px solid #9f9f9f;
    border-radius: 0;
    appearance: auto;
    -webkit-appearance: auto;
    -moz-appearance: inherit;
}
#contact-form .form-control:focus {
    box-shadow: none;
}

.iti__dropdown-content{
    width:350px !important;
}

.dealer-banner-title{
    background: #ffffffd1;
    color: var(--global-color-primary);
    padding: 40px 40px 40px 0px;
    position: relative;
}

.banner-section .banner-content .banner-title:before{
    position: absolute;
    content: '';
    top: 0;
    left: -80%;
    width: 80%;
    height: 100%;
    background: #ffffffd1;
}

.banner-section .banner-content .banner-title h2{
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    font-weight: 700;
    line-height: 49px;
}

.banner-section .banner-form{
    background-color: var(--global-color-accent);
    padding: 50px 30px 30px;
    margin-top: 100px;
    position: relative;
}

.banner-section .banner-form .top-logo{
    position: absolute;
    top: -30px;
    width: 110px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: 4px;
}

.banner-section .banner-form label{
    font-weight: 600;
    font-size: 14px;
}

.banner-section .banner-form input, .banner-section .banner-form textarea{
    font-size: 14px;
}

.form-group{
    margin-bottom: 10px;
}

.banner-section .banner-form h3{
    color: var(--global-color-primary);
    text-align: center;
    margin-bottom: 20px;
}

.banner-section .banner-form p{
    color: var(--global-color-primary);
}

.banner-section .banner-form small{
    font-size: 11px;
}

.screen-sec{
    background: #F3F3F3;
    padding: 80px 0px;
}

.screen-card{
    position: relative;
    overflow: hidden;
    transition: 0.5s;
    min-height: 322px;
    height: 322px;
}

.screen-card img{
    transition: .5s;
    object-fit: cover;
    min-height: 322px;
    /* height: 322px; */
}

.sc-content{
    transition: .5s;
    color: #fff;
    background-color: #00000073;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
}

.sc-content h4{
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: var(--e-global-color-white);
    transition: .5s;
}

.sc-content p{
    color: var(--e-global-color-white);
    margin-bottom: 40px;
    transition: .5s;
}

.sc-content p, .sc-content .btn{
    display: none;
    transition: .5s;
}

.screen-card:hover img{
    transform: scale(1.2);
    transition: .5s;
}

.screen-card:hover .sc-content{
    transition: .5s;
    background-color: #052237E3;
    text-align: left;
    justify-content: space-between;
    align-content: center;
}

.sc-content h2{
    width:30%;
}
.screen-card:hover .sc-content h2{
    width:100%;
}

.screen-card:hover .sc-content p, .screen-card:hover .sc-content .btn{
    display: block;
    transition: .5s;
}

.sc-content .btn{
    border-radius: 2px;
    font-size: 12px;
}

.section-title-light h4{
    color: var(--global-color-secondary);
    font-family: "Poppins", Sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.about-section-title-light h4{
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.about-banner-title>h2{
    color: #fff;
}

.section-title-light .sub-heading{
    color: var(--global-color-primary);
    font-family: "Poppins", Sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.section-title-light .heading{
    color: var(--global-color-primary);
    font-family: "Poppins", Sans-serif;
    font-size: 35px;
    font-weight: 300;
    line-height: 45px;
}

.section-title-dark .sub-heading{
    color: var(--global-color-accent);
    font-family: "Poppins", Sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.section-title-dark .heading{
    color: var(--global-color-accent);
    font-family: "Poppins", Sans-serif;
    font-size: 35px;
    font-weight: 300;
    line-height: 45px;
}

.dealer-showcase{
    background: #00101C;
    padding: 80px 0px;
}

.showcase-slider{
    padding-top: 60px!important;
}

.showcase-slider-wrapper{
    padding-left: 0px!important;
    padding-right: 0px!important;
    overflow: hidden;
    position: relative;
}

.btn-filter{
    position: absolute;
    left: 150px;
    top: 8px;
    z-index: 2;
}

.btn-filter .btn{
    cursor: pointer;
}

.btn-filter .btn.active{
    background: var(--global-color-accent);
    color: var(--global-color-primary);
}

.fenetex-slider{
    display: none;
}

.showcase-slider .swiper-control{
    position: absolute;
    top: 25px;
    right: 10px;
}

.showcase-slider .swiper-control .swiper-button-prev{
    left: var(--swiper-navigation-sides-offset,-110px);
}

.swiper-button-next, .swiper-button-prev{
    border: 1.5px solid var(--global-color-accent);
    width: 40px!important;
    height: 40px!important;
}


.swiper-slide img{
    max-height: 570px;
    width: 100%;
    object-fit: cover;
}

.swiper-button-next:after, .swiper-button-prev:after{
    font-size: 18px!important;
    color: var(--global-color-accent);;
}

.why-fenetex{
    background: var(--global-color-primary);
    /* padding: 80px 0px; */
}

.why-fenetex .section-title-dark p{
    color: #fff;
    max-width: 750px;
    margin: 0 auto;
}

.why-fenetex .section-title-dark p span{
    color: var(--global-color-accent);
    font-weight: 600;
}

.wf-col{
    padding: 30px 20px;
    text-align: center;
}

.wf-col h3{
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-top: 20px;
}

.wf-col p{
    color: #FFFFFF7A;
    font-size: 11px;
}

.above-footer-row{
    background-color: #fff;
    background-image: linear-gradient(90deg, var(--global-color-accent) 50%, var(--global-color-secondary) 49%);
}

.get-in-touch{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px auto 5px;
}

.get-touch-text{
    padding: 0px 15px;
}

.get-in-touch h3{
    color: var(--global-color-primary);
    font-size: 18px;
    font-weight: 600;
}

.get-in-touch p{
    color: var(--global-color-primary);
    font-size: 13px;
    font-weight: 400;
}

.see-our-product{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px auto 5px;
}

.see-product-text{
    padding: 0px 15px;
}

.see-product-text h3{
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.see-product-text p{
    color: #fff;
    font-size: 13px;
    font-weight: 400;
}

footer{
    background: var(--global-color-primary);
}

.footer-main{
    padding: 60px 0px;
}

.footer-main.dealer-footer{
    padding: 0px 0px;
    overflow: hidden;
    min-height: 250px;
}

.dealer-footer-contact{
    margin-top: -250px;
    padding-top: 30px;
}

footer ul{
    padding-left: 0px;
}

footer ul li{
    list-style-type: none;
}

footer h3{
    color: var(--global-color-white);
    font-size: 18px;
    font-weight: 500;
}

footer h3 a{
    color: var(--global-color-white);
}

footer ul li div{
    font-size: 13px;
    font-weight: 300;
    color: #A6A9AF;
    padding-top: 5px;
    padding-bottom: 5px;
}
footer ul li div a{
    font-size: 13px;
    font-weight: 300;
    color: #A6A9AF;
    padding-top: 5px;
    padding-bottom: 5px;
}

.footer-logo{
    text-align: right;
}

.footer-logo h2{
    color: #A6A9AF;
    font-size: 18px;
    font-weight: 400;
    margin: 15px 0px;
}

.footer-logo h2 a{
    color: #A6A9AF;
}

.footer-logo h3{
    color: #A6A9AF;
    font-size: 15px;
    font-weight: 400;
    margin: 15px 0px;
}

.footer-logo h3 a{
    color: #A6A9AF;
}

.footer-social{
    text-align: right;
    display: flex;
    color: #fff;
    justify-content: end;
}
.footer-social i{
    height: 40px;
    width:40px;
    background-color: #353D46;
    margin:5px 9px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.footer-social a{
    background: #353D46;
    margin-left: 20px;
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
    color: #fff;
    font-size: 20px;
}

.copyright-row{
    border-top: 1px solid #ffffff;
    padding: 15px 0px;
}

.copyright-row a{
    color: #fff;
    margin-right: 20px;
}

.copyright-row .left{
    /* font-size: 13px; */
    color: #A6A9AF;
}

.copyright-row .right
{
    text-align: right;
    color: #A6A9AF;
}

.city-product-card{
    background: var(--global-color-white);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.cp-content{
    padding: 15px;
}

.cp-content h4{
    font-size: 18px;
}

.city-video-sec{
    background: #00101C;
    padding: 80px 0px 0px;
}

.city-video-sec .youtube-wrap iframe{
    position: relative;
    top: 7px;
}

.commitment-sec{
    background: var(--global-color-primary);
    padding: 80px 0px;
}

.commit-grid{
    padding: 0px 40px;
}

.commit-grid img{
    border-radius: 15px;
}

.commit-content{
    color: #fff;
    padding: 20px 0px;
}

.customization-sec{
    padding: 80px 0px;
}

.customization-sec p{
    max-width: 400px;
}

.swatch-imgs{
    margin-top: 45px;
}

.swatch-imgs img{
    max-width: 60px;
    margin-right: 20px;
}

.customization-sec .section-title-light h3 {
    font-size: 30px;
    line-height: 39px;
}
.section-title-light h2{
    color: var(--global-color-primary);
    font-weight: 300;
    font-size: 35px;
    margin-bottom: 20px;
}

.banner-form.city-form{
    padding: 30px;
}
.home-banner-section {
    position: relative;
    height: calc(100vh - 100px);
    max-height: 700px;
}

.home-banner-section .banner-bg{
    height: 100%;
    overflow: hidden;
    position: absolute;
    width: 100%;
}

.home-banner-section .banner-bg .banner-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.why-choose-us{
    background-color: var(--global-color-primary);
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 100px 1px 100px 1px;
}

.why-choose-us-header h2{
    font-family: "Poppins", Sans-serif;
    font-size: 35px;
    font-weight: 500;
}

.why-choose-us-header p{
    color: #6F7486;
    font-family: "Poppins", Sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.why-choose-us-header .icon-wrapper svg{
    height: 1rem;
    width: 1rem;
    display: block;
}

.choose-us-item-wrapper:hover h3, .choose-us-item-wrapper:hover p{
    color: #000;
}

.choose-us-item-wrapper:hover .icon-wrapper svg path{
    fill: var(--global-color-primary) !important
}
.choose-us-item-wrapper h3{
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    margin-top: 15px;
}

.choose-us-item-wrapper p{
    color: #fff;
    font-size: 12px;
    font-weight: 400;
}

.choose-us-item-wrapper{
    margin: 5px 5px 5px 5px;
    padding: 15px 15px 15px 15px;
}
.why-choose-us .row > div{
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    border-color: #505050;
}

.why-choose-us .row > div:last-child{
    border-width: 0;
}

.choose-us-item-wrapper:hover {
    background: #fff;
}

.home-banner-section .banner-content{
    position: absolute;
    top: 45%;
    width: 38%;
    transform: translateY(-50%);
}

.dealer-banner-section .banner-content{
    position: absolute;
    top: 2%;
    width: 38%;
    transform: none;
}

/*.home-banner-section .banner-content .banner-title{
        background: #ffffffd1;
    color: var(--global-color-primary);
    padding: 40px 40px 40px 0px;
    position: relative;
}

.home-banner-section .banner-content .banner-title:before{
    position: absolute;
    content: '';
    top: 0;
    left: -80%;
    width: 80%;
    height: 100%;
    background: #ffffffd1;
}
*/


.home-banner-overlay{
    width: 59%;
    max-width: 59%;
    transform: skew(10deg);
    background-color: #052237BD;
    height: 100vh;
    position: absolute;
    top: 0;
    left: -90px;
}

.home-about .about-left .section-title-light h2.heading{
    font-size: 30px;
    line-height: 39px;
}

.home-about .about-left{
    padding: 0px 30px 0px 0px;
}

.lifetime-warranty{
    background: #00101C;
    margin-top: 150px;
}

.lifetime-warranty img{
    max-width: 240px;
    margin-top: -180px;
}

.lifetime-warranty h2{
    color: var(--global-color-white);
    font-size: 28px;
    font-weight: 500;
}

.lifetime-warranty h2 span{
    font-weight: 700;
    color: var(--global-color-accent);
}

.video-wrapper{
    padding-bottom: 56.25%;
    position: relative;
}

.video-wrapper iframe{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 0;
    background-color: #000;
}

.video-wrapper img{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 0;
    background-color: #000;
    object-fit: cover;
    z-index: 2;
    cursor: pointer;
}
.choose-us-item-wrapper:hover .icon-wrapper svg path{
    fill: var(--global-color-primary) !important
}

.request-estimate{
    padding: 45px 0px;
    background: var(--global-color-secondary);
}

.request-estimate h3{
    color: var(--global-color-white);
    font-size: 30px;
    font-weight: 600;
}

.unmatched-excellence{
    background: #F3F3F3;
}

.unmatch-list{
    width: 28%;
    float: left;
}

.unmatch-slider{
    width: 72%;
    float: left;
}

.unmatch-list{
    margin-top: 80px;
    background: var(--global-color-white);
    border-radius: 5px;
    border: 1px solid rgba(9,9,9,.1);
}

.unmatch-list ul{
    padding: 0px 25px;

}

.unmatch-list li{
    list-style-type: none;
    font-size: 12px;
    font-weight: 400;
    border-bottom: 1px solid #87878740;
    padding: 15px 10px;
}

.unmatch-list li:last-child{
    border: none;
}

.unmatch-slider .pricing-list{
    background: var(--global-color-white);
    border-radius: 8px;
    border: 1px solid rgba(9,9,9,.1);
    color: var(--global-color-text);
}

.unmatch-slider .pricing-list.list-1{
    background: var(--global-color-primary);
}

.unmatch-slider .pricing-list.list-2{
    background: #00101C;
}

.unmatch-slider .pricing-list .pl-title{
    height: 80px;
    padding: 20px;
}

.unmatch-slider .pricing-list ul{
    padding-left: 0px;
    padding: 0px 10px;
}

.unmatch-slider .pricing-list li{
    list-style-type: none;
    font-size: 12px;
    font-weight: 400;
    border-bottom: 1px solid #87878740;
    padding: 15px 5px;
    min-height: 49px;
    position: relative;
    text-align: center;
}

.unmatch-slider .pricing-list li:last-child{
    border: none;
}

.pricing-list li img{
    width: 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50% , -50%);
    left: 50%;
}

.haq-section{
    background: var(--global-color-primary);

}

.haq-left{
    padding: 50px 70px 30px 0px;
}
.haq-left h2{
    color: var(--global-color-accent);
    font-size: 35px;
    font-weight: 500;
    line-height: 45px;
}

.haq-left p{
    color: var(--global-color-white);
    font-size: 18px;
    font-weight: 400;
    line-height: 1.7;
    margin-top: 30px;
}

.haq-right{
    padding: 30px 30px 30px 30px;
    background-color: #FFFFFF36;
}

.haq-right h3{
    color: #fff;
    font-size: 26px;
    margin: 0 0 20px 0;
}

.haq-right .form-group{
    margin-bottom: 20px;
}

.haq-right .form-group input , .haq-right .form-group select , .haq-right .form-group textarea{
    background: rgb(255 255 255 / 50%) !important;
    font-size: 11px;
    color: #fff !important;
    padding: 6px 10px;
    width: 100%;
    line-height: 1.3;
    border: none;
    border-radius: 2px;
}

.haq-right .form-group input , .haq-right .form-group select{
    height: 38px;
}

.haq-right .form-group .iti{
    width: 100%;
}

.haq-right .form-group input::placeholder , .haq-right .form-group select::placeholder , .haq-right .form-group textarea::placeholder{
    color: var(--global-color-white);
}

.product-why-sec{
    background: var(--global-color-accent);
    padding-top: 10px;
}

.product-why-sec .wf-col{
    padding: 30px 20px;
    text-align: center;
}

.product-why-sec .wf-col h3{
    font-size: 15px;
    font-weight: 500;
    color: var(--global-color-primary);
    margin-top: 20px;
}

.product-why-sec .wf-col p{
    color: var(--global-color-primary);
    font-size: 11px;
}

.explore-tech-sol{
    background: #F3F3F3;
}

.ets-imgs{
    display: flex;
}

.ets-img{
    padding: 10px 15px;
    border-right: 1px solid var(--global-color-text);
    flex-grow: 1;
    flex-basis: 0;
}

.ets-img img{
    max-width: 120px;
    max-height: 30px;
}

.project-sec{
    background: var(--global-color-primary);
}

.project-slider{
    padding-top: 90px!important;
}

.project-slider .swiper-slide {
  width: 60%!important;
}

.project-slider .swiper-slide img {
    border-radius: 0px;
}

.project-slider .swiper-control{
    position: absolute;
    top: 30px;
    left: 50%;
}

.project-slider .swiper-button-prev{
    left: calc(var(--swiper-navigation-sides-offset, 50%) - 50px);
    top: 30px;
}

.project-slider .swiper-button-next{
    right: calc(var(--swiper-navigation-sides-offset,50%) - 50px);
    top: 30px;
}

.single-product-banner{
    height: 83vh;
    overflow: hidden;
    position: relative;
    background: #F4F4F4;;
}

.single-product-banner .banner-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
}

.single-product-banner .banner-img img{
    max-height: 83vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.single-product-banner .banner-content{
    padding: 30px 50px;
}

.single-product-banner .banner-content h1{
    font-size: 73px;
    font-weight: 400;
    line-height: 1;
    color: var(--global-color-primary);
}

.single-product-banner .banner-content p{
    font-size: 15px;
    font-weight: 300;
    line-height: 1.7;
    margin-bottom: 20px;
    margin-top: 20px;

}
.single-product-banner .btn-dark-outline{
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 500;
    border-radius: 90px 90px 90px 90px;
    padding: 7.5px 23px;
    color: var(--global-color-darkgray);
    background-color: #F9CB3100;
    border: 1px solid var(--global-color-darkgray);
}

.product-page-nav{
    margin-top: -58px;
    position: relative;
}

.product-page-nav .navigation{
    padding: 17px 20px;
    background-color: var(--global-color-accent);
    border-radius: 20px 20px 0px 0px;
}

.product-page-nav .navigation ul{
    display: flex;
    justify-content: space-around;
    margin: 0px;
    width: 90%;
    margin: 0 auto;
}

.product-page-nav .navigation ul li{
    list-style-type: none;
}

.product-page-nav .navigation ul li a{
    text-transform: uppercase;
    color: var(--global-color-darkgray);
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
}

.screen-features{
    padding: 20px 0px;
}

.screen-features h3{
    font-size: 13px;
    font-weight: 500;
    color: var(--global-color-secondary);
    margin: 15px 0px;
}

.features-sec{
    background: var(--global-color-primary);
}

#feature_accordion .accordion-item{
    background: transparent;
    border-color: var(--global-color-accent);
}

#feature_accordion .accordion-item button{
    background: transparent;
    color: var(--global-color-white);
    outline: none!important;
    padding: 10px 0px;
    box-shadow: none;
}

#feature_accordion .accordion-item .accordion-body{
    padding: 10px 0px;
    color: var(--global-color-white);
}

.features-sec .img-box{
    width: 75%;
    margin: 0 auto;
}

.features-sec{
    background: var(--global-color-primary);
}
#total_accordion .accordion-item{
    background: transparent;
    border-color: var(--global-color-secondary);
}

#total_accordion .accordion-item button{
    background: transparent;
    color: var(--global-color-secondary);
    outline: none!important;
    padding: 10px 0px;
    box-shadow: none;
}

#total_accordion .accordion-item .accordion-body{
    padding: 10px 0px;
}

.product-options{
    background: #F3F3F3;
}

#accordion-style-two .accordion-item{
    border: none;
}

#accordion-style-two .accordion-item button{
    background: transparent;
    color: var(--global-color-secondary);
    outline: none!important;
    box-shadow: none;
    border: 1px solid #ddd;
    border-left: 2px solid var(--global-color-secondary);
    font-size: 13px;
    font-weight: 600;
}

#accordion-style-two .accordion-item .accordion-body{
    color: var(--global-color-secondary);
    font-size: 13px;
}

#accordion-style-two .accordion-body li{
    margin-bottom: 10px;
}

.banner-left-form{
    max-width: 90%;
    padding: 20px 20px 10px 20px;
    background-color: var(--global-color-white);
    border-radius: 20px;
}

.banner-left-form h3{
    font-size: 23px;
    font-weight: 700;
    color: var(--global-color-primary);
}

.banner-left-form input, .banner-left-form select{
    color: #333;
    outline: none!important;
    box-shadow: none!important;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #a7a7a7;
    font-size: 16px;
    padding: 6px 10px;
    height: 38px;
    line-height: 1.3;
}

.testimonial-section{
    padding: 0px 0px 0px 0px;
}
.testimonial-title{
    color: #00a4e0;
    font-size: 13px;
    font-weight: 400;
}
.testimonial-sub-title{
    color: var(--global-color-text);
    font-size: 30px;
    font-weight: 300;
}
.testimonial-slider {
    padding-top: 20px;
    padding-bottom: 20px;
}
.testimonial-item{
    margin: 0px 0px 0px 0px;
    padding: 0px 5px 0px 5px;
}
.testimonial-content{
    margin: 0;
    text-align: left;
    display: flex;
    flex-direction: column-reverse;
    height: 200px;
    justify-content: flex-end;
    box-shadow: 0 0 30px #cccccc4a;
    padding: 10px;
}
.testimonial-text{
    color: #7a7a7a;
    line-height: 1.5;
    margin: 15px 0 !important;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
}
.testimonial-star-rating{
    margin: 15px 0 0 !important;
    padding: 0;
}
.testimonial-star-rating li{
    list-style: none;
    display: inline-block;
}
.testimonial-star-rating li i{
    color: #f2b01e;
}
.testimonial-user{
    color: #333333;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 5px;
}
.testimonial-user-company{
    color: #AAAAAA;
    font-family: "Poppins", Sans-serif;
    font-size: 11px;
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    line-height: 1.75;
    margin-bottom: 0;
    margin-top: 1px;
}
.testimonial-slider .swiper-button-next, .testimonial-slider .swiper-button-prev {
    margin-bottom: 0;
    border-radius: 50% !important;
    background-color: #FCFBFA !important;
    border-color: #AEAEAE !important;
    color: #AEAEAE !important;
}
.testimonial-slider .swiper-button-prev:after, .testimonial-slider .swiper-button-next:after{
    color: #AEAEAE;
}
/* .swiper-button-prev, .swiper-button-next {
    width: 40px !important;
    height: 40px !important;
    background-image: none;
    outline: none;
    font-size: 22px;
    background-color: #FCFBFA;
    color: #AEAEAE;
    border-radius: 100% 100% 100% 100%;
    position: unset !important;
    display: inline-flex !important;
} */

.dealer-logo{
    /* position: absolute; */
    /* top: -30px; */
    width: 215px;
    /* left: 50%; */
    background: #fff;
    border-radius: 4px;
}

.banner-left-form{
    max-width: 100%;
    padding: 20px 20px 10px 20px;
    background-color: var(--global-color-white);
    border-radius: 20px;
}

.banner-left-form h3{
    font-size: 23px;
    font-weight: 700;
    color: var(--global-color-primary);
}

.banner-left-form input, .banner-left-form select{
    color: #333;
    outline: none!important;
    box-shadow: none!important;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #a7a7a7;
    font-size: 16px;
    padding: 6px 10px;
    height: 38px;
    line-height: 1.3;
}


.option-tab-container{
    list-style: none;
    padding-left: 0;
    color: var(--global-color-primary);
    font-size: 1rem;
    font-weight: 600;
}
.option-tab{
    padding-bottom:2px;
    padding-top:10px;
}
.option-tab:hover{
    border-bottom: 1px solid var(--global-color-secondary);
    width:30%;
    cursor: pointer;
}
.active-option-tab{
    border-bottom: 1px solid var(--global-color-secondary);
    width:30%;
}

.navigation ul li{
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
}

.cursor-pointer{
    cursor: pointer;
}

.product-title{
    width:90%;
}

/* p {
    font-size: 1em !important;
    line-height: 1.7;
}    */

.nav-link, .dropdown-item{
    cursor: pointer;
}

.estimate-form{
    margin-top: 13px;
}

.banner-left-form .form-control{
    appearance: auto!important;
}

.navbar-nav .dropdown-item{
    text-transform: uppercase;
    padding: 13px 20px;
    font-size: 13px;
}

.navbar-nav .dropdown-item:hover , .navbar-nav .dropdown-item:focus , .navbar-nav .dropdown-item:active{
    background-color: var(--global-color-secondary);
    color: var(--global-color-white);
} 

.navbar-expand-lg .navbar-nav .dropdown-menu{
    border-radius: 0px;
    padding: 0px;
}

.navbar-nav .nav-link{
    cursor: pointer;
}

.features-sec .section-title-dark .heading{
    color: var(--global-color-accent);
}

/* @media (max-width: 425px){
    .unmatch-list{
        width: 60%;
        float: left;
    }

    .unmatch-slider{
        width: 40%;
        float: left;
    }
} */

.mb-30{
    margin-bottom: 30px;
}

.accordion-flush .accordion-item:last-child{
    border-bottom: 1px solid !important;
}

.features-sec .accordion-flush .accordion-item:last-child{
    border-color: var(--global-color-accent) !important;
}

.product-options .section-title-light .heading{
    font-weight: 500;
}

.total-control-sec .section-title-light .heading{
    font-size: 30px;
    font-weight: 500;
}
.accordion-flush .light-accordian:last-child{
    border-bottom: 1px solid var(--global-color-secondary) !important;
}

.accordion-flush .dark-accordion:last-child{
    border-bottom: 1px solid var(--global-color-accent)  !important;
}

#menu-1-bd8e0b3 li{
    cursor: pointer;
}

/* .react-tel-input {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
}
.react-tel-input .flag-dropdown{
    position: unset !important;
    background-color: rgb(255 255 255 / 50%) !important;
    border:none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.react-tel-input input{
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
} */

.haq-right .react-tel-input .flag-dropdown{
    background-color: rgb(157 168 176) !important;
    border:none !important;
}
.react-tel-input .form-control{
    padding-left: 10px !important;
}
.flag-dropdown{
    display: none;
}
.haq-right .react-tel-input .flag-dropdown .selected-flag:hover,.haq-right .react-tel-input .flag-dropdown .selected-flag:focus{
    background-color: rgb(157 168 176) !important;
}
.haq-right .react-tel-input input{
    padding-left: 10px !important;
}

.react-tel-input{
    font-family: inherit !important;
    }
.estimate-phone>.form-control {
    font-size: 16px !important;
}

#estimate-form .react-tel-input .flag-dropdown{
    border :none !important;
    margin-bottom: 2px;
    background-color: unset;
}
#estimate-form .react-tel-input .form-control{
    width:100% !important;
}
#estimate-form .react-tel-input{
    margin-top: 5px;
}

#contact-form .react-tel-input .flag-dropdown{
    border :none !important;
    margin-bottom: 2px;
    background-color: unset;
}
#contact-form .react-tel-input .form-control{
    width:100% !important;
}
#contact-form .react-tel-input{
    margin-top: 2px;
}

.navbar-toggler:focus{
    outline: none!important;
    box-shadow: none!important;
}

.swiper-button-prev, .swiper-button-next{
    z-index: 1!important;
}

@media (min-width: 1440px){
    .single-product-banner{
        height: 65vh;
    }

    .single-product-banner .banner-content{
        padding: 50px 50px
    }

    .dealer-banner-section .banner-content {
        position: absolute;
        top: 5%;
        width: 38%;
    }
}

@media (min-width: 1600px){
    .single-product-banner{
        height: 55vh;
    }

    .single-product-banner .banner-content{
        padding: 60px 50px
    }

    .dealer-banner-section .banner-content {
        position: absolute;
        top: 10%;
        width: 38%;
    }
    
}

@media (min-width: 1920px){

    .dealer-banner-section .banner-content {
        position: absolute;
        top: 10%;
        width: 30%;
    }
    
}

@media (min-width: 2100px) {
    .dealer-banner-section .banner-content {
        width: 25%;
    }
}

@media (max-width: 1366px){
    .banner-left-form input, .banner-left-form select{
        height: 30px;
        padding: 3px 10px;
    }

    .banner-left-form{
        padding: 10px 20px 3px;
    }

    .react-tel-input .form-control{
        height: 30px!important;
    }
}

@media (max-width: 1199px){
    .single-product-banner{
        height: auto!important;
    }

    .product-page-nav{
        margin-top: 30px!important;
    }
}

@media (max-width: 1024px){
    .warranty-img{
        left: 0px!important;
    }

    .features-sec .img-box {
        width: 95%!important;
    }

    .features-sec .section-title-dark{
        padding-top: 0px;
    }
}

@media (max-width: 1023px){
    .single-product-banner .banner-content {
        padding: 20px 20px!important;
    }

    .single-product-banner .banner-content h1{
        padding-top: 0px!important;
        font-size: 44px!important;
    }

}

@media (max-width: 991px){
    .navbar-nav{
        display: block;
    }

    .navbar-collapse{
        text-align: center;
    }

    .navbar-nav .nav-item{
        padding-top: 0px!important;
        padding-bottom: 0px!important; 
    }

    .navbar-nav .nav-item .nav-link{
        padding-top: 8px!important;
        padding-bottom: 8px!important; 
    }

    .navbar-nav .nav-link.active{
        background-color: var(--global-color-secondary);
        border-bottom: none!important;
    }

    .magnet-features .feature-block {
        padding: 20px;
    }

    .screen-card{
        margin-bottom: 30px;
    }

    #feature_accordion{
        margin-bottom: 20px;
    }
}

@media (max-width: 990px){
    .features-item-slider .carousel-content p{
        display: none;
    }
}

@media (max-width: 768px){
    
    .haq-left{
        padding-top: 20px!important;
    }

    .features-item-slider .swiper-slide{
        overflow: hidden;
    }

    .screen-features img{
        margin-bottom: 20px;
    }

    .features-sec .section-title-dark{
        padding-top: 0px!important;
    }

    .contact-detail {
        padding: 0px 50px 0px 50px!important;
    }
}

@media (max-width: 767px){

    .about-left{
        padding: 0px!important;
    }
    .home-about .about-left{
        padding-top: 0px!important;
    }
    .features-warranty-section img {
        margin: 0px!important;
        max-width: 130px!important;
    }

    .features-warranty-section{
        max-width: 240px!important;
        margin-top: 0px!important;
        margin-bottom: 0px!important;
        margin: 0 auto;
    }

    .features-warranty-section > div{
        margin-bottom: 20px;
    }

    .magnet-features .feature-block {
        padding: 20px 40px;
    }

    .onetrack-section .features-warranty-section {
        margin: 30px auto!important;
    }

    .common-features-text{
        width: 100%!important;
    }

    .features-list button{
        margin-bottom: 10px;
    }

    .features-list .modal-body .pe-5{
        padding: 20px 30px!important;
    }

    .single-product-banner .banner-img{
        position: relative!important;
        width: 100%!important;
        height: 100%;
        opacity: 0.7;
    }

    .single-product-banner{
        height: 60vh!important;
        background: #000!important;
    }

    .single-product-banner .banner-content h1 , .single-product-banner .banner-content p{
        color: #fff!important;
    }

    .single-product-banner .btn-dark-outline{
        color: #fff!important;
        border-color: #fff!important;
    }

    .product-page-nav {
        margin-top: 20px!important;
        position: relative;
    }

    .product-page-nav .navigation{
        border-radius: 20px;
    }

    .product-page-nav .navigation ul{
        flex-wrap: wrap;
        width: 100%!important;
    }

    .product-page-nav .navigation ul li{
        flex: 0 0 33%;
        margin: 5px auto;
    }

    .contact-detail {
        padding: 0px 15px 0px 15px!important;
    }

    .screen-card{
        margin-bottom: 15px;
    }

    .features-sec .img-box {
        width: 100%!important;
        margin-bottom: 20px!important;
    }

    .spb-content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50% , -50%);
        z-index: 1;
    }
    
}

@media (max-width: 575px){
    #top-bar {
        height: unset !important;
        text-align: center!important;
    }

    .top-bar-right {
        display: flex !important;
        justify-content: center;
    }

    .product-page-nav .navigation ul li{
        flex: 0 0 50%;
        margin: 5px auto;
    }

    .screen-features img{
        margin-bottom: 20px;
    }

    .total-control-sec{
        padding-top: 50px!important;
    }
}

@media (max-width: 480px){
    .swatch-imgs img {
        max-width: 50px!important;
        margin-right: 15px!important;
    }

    .footer-social{
        justify-content: left!important;
    }

    .active-option-tab{
        width: 40%!important;
    }

    .single-product-banner .banner-img img{
        max-height: 100vh!important;
    }
}