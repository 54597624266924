@import './vars.css';


.tech-banner-section{
    overflow: hidden;
    position: relative;
}
.background-video-embed{
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    height: 100vh;
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: -2;
}

.overlay-background{
    background-color:#000000;
    /* position: absolute; */
    height: 100vh;
    width:100%;
    opacity: 0.5;
    z-index: -1;
}

.overlay-text{
    color: var(--global-color-white);
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    line-height: 1.2;
    font-weight: 300;
    position:absolute;
    z-index: 1;
    top: 0px;
    left: 0;
    text-align: center;
    width:100%;
    height:90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.overlay-text>b{
    font-weight: bolder;
}

.tech-banner-overlay{
    width: 100%;
    background-color: #052237BD;
    height: 100vh;
    /* position: absolute; */
    top: 0;
}

.tech-accordion>.accordion-button{
    padding:15px !important;
}

.tech-desc-section{
    background-color: var(--global-color-primary);
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 100px 1px 100px 1px;
}

.tech-desc>h2{
    color: var(--global-color-accent);
    font-size: 1.5em !important;
}

.tech-desc>.tech-parent-desc{
    color: var(--global-color-white);
}

.tech-desc>.tech-sub-desc{
    color: var(--global-color-white);
    font-size: 13px;
    line-height: 1.7;
}
.tech-sub-para{
    color: var(--global-color-white) !important;
    line-height: 1.7;
}

.tech-section-img{
    width:77%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.tech-processing-para{
    font-size: 1em;
    line-height: 1.7;
    color: var(--global-color-primary);
}

@media (max-width: 768px){
    .overlay-text{
        font-size: 38px;
    }
}

@media (max-width: 480px){
    .overlay-text{
        font-size: 30px;
        height: 50vh;
    }

    .overlay-background{
        height: 50vh;
    }

    .background-video-embed{
        height: 65vh;
    }



    
}

@media (max-width: 375px){
    .overlay-text{
        font-size: 26px;
    }
}