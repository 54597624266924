@import './vars.css';

.topbar-btn{
    color: #fff;
    font-size: 13px;
}

.find-location-ul{
    position: relative;
}

.find-location{
    margin-left: 10px;
}


.find-location-li{
    display: flex;
}

.zipcode-field{
/*    display: none;*/
    position: relative;
    width: 100%;
    transition: 2s;
    overflow: hidden;
}

.zipcode-field .input-group-append{
    position: absolute;
    right: 10px;
    z-index: 3;
    top: 4px;
}

.zipcode-field .input-group-append i{
    color: #0177c1;
}

.find-location-ul.show .zipcode-field{
    display: flex;
    z-index: 999;
    width: 100%;
    transition: 2s;
}

.find-location-ul.show .zipcode-field input{
    font-size: 12px!important;
    max-width: 150px;
}

.find-location-ul .zipcode-field input{
    padding: 4px 30px 4px 13px;
    color: #000;
    background: #dfdfdf!important;
    max-width: 150px;
    border: none;
}

.find-location-ul.show .zipcode-field .btn{
    background: #00aeef;
    border-color: #00aeef;
    color: #fff;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

body{
    font-family: 'Poppins', sans-serif;
}

/* p{
    color: var(--global-color-white);
    font-size: 1em;
    line-height: 1.7;
} */

.light-section p strong{
    color: var(--global-color-primary);
}

.dark-section p strong{
    color: #ffffff;
}

.my-80{
    margin-top: 80px;
    margin-bottom: 80px;
}

.py-80{
    padding-top: 80px;
    padding-bottom: 80px;
}

.py-100{
    padding-top: 100px;
    padding-bottom: 100px;
}

.pt-80{
    padding-top: 80px;
}

.pb-80{
    padding-bottom: 80px;
}

.logo p{
    font-size: 11px;
    color: #fff;
    border-top: 1px solid #fff;
    padding-top: 2px;
    margin-top: 7px;
    margin-bottom: 0px;
    padding-left: 23px;
}

.main-header{
    width: 100% !important;
    background-color: var(--global-color-primary);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    transition: all 0.5s ease;
    min-height: 70px;
}

.fixed {
    position: fixed;
    top:0; left:0;
    width: 100%;
    z-index: 9; 
    animation: smoothScroll 1s forwards;
}
@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

.logo span{
    color: var(--global-color-accent);
}

.navbar-nav .nav-link.active{
    color: #fff;
    border-bottom: 1px solid #fff;
}

.navbar-nav .nav-link{
    font-family: "Poppins", Sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
    text-transform: uppercase;
    padding: 0px!important;
}

.navbar-nav .nav-link a{
    color: #fff;
}

.navbar-nav .nav-item {
    padding-right: 0.7rem!important;
    padding-left: 0.7rem!important;
    padding-top: 4px;
    padding-bottom: 4px;
}

.nav-item .dropdown-toggle::after{
    font-size: 20px;
    color: #fff;
    top: 3px;
    position: relative;
}


.banner-section {
    position: relative;
    height: 100vh;
    max-height: 700px;
}

.banner-section .banner-bg{
    height: 100%;
    overflow: hidden;
    position: absolute;
    width: 100%;
}

.banner-section .banner-bg .banner-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner-section .banner-content{
    position: absolute;
    top: 50%;
    width: 38%;
    transform: translateY(-50%);
}

.banner-section .banner-content .banner-title{
        background: #ffffffd1;
    color: var(--global-color-primary);
    padding: 40px 40px 40px 0px;
    position: relative;
}

.banner-section .banner-content .banner-title:before{
    position: absolute;
    content: '';
    top: 0;
    left: -80%;
    width: 80%;
    height: 100%;
    background: #ffffffd1;
}

.banner-section .banner-content .banner-title h2{
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    font-weight: 700;
    line-height: 49px;
}

.banner-section .banner-form{
    background-color: var(--global-color-accent);
    padding: 50px 30px 30px;
    margin-top: 100px;
    position: relative;
}

.banner-section .banner-form .top-logo{
    position: absolute;
    top: -30px;
    width: 110px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: 4px;
}

.banner-section .banner-form label{
    font-weight: 600;
    font-size: 14px;
}

.banner-section .banner-form input, .banner-section .banner-form textarea{
    font-size: 14px;
}

.form-group{
    margin-bottom: 10px;
}

.banner-section .banner-form h3{
    color: var(--global-color-primary);
    text-align: center;
    margin-bottom: 20px;
}

.banner-section .banner-form p{
    color: var(--global-color-primary);
}

.banner-section .banner-form small{
    font-size: 11px;
}

.about{
    margin: 80px auto;
}

.about-left{
    padding-left: 30px;
    padding-top: 0px;
}

.about-left .section-title-light h3{
    font-size: 25px;
    line-height: 35px;
}

.screen-sec{
    background: #F3F3F3;
    padding: 80px 0px;
}

.sc-content{
    transition: .5s;
    color: #fff;
    background-color: #00000073;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
}

.sc-content h2{
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: var(--e-global-color-white);
    transition: .5s;
}

.sc-content p{
    color: var(--e-global-color-white);
    margin-bottom: 40px;
    transition: .5s;
}

.sc-content p, .sc-content .btn{
    display: none;
    transition: .5s;
}

.sc-content .btn{
    border-radius: 2px;
    font-size: 12px;
}

.dealer-showcase{
    background: #00101C;
    padding: 80px 0px;
}

.showcase-slider{
    padding-top: 60px!important;
}

.showcase-slider-wrapper{
    padding-left: 0px!important;
    padding-right: 0px!important;
    overflow: hidden;
    position: relative;
}

.fenetex-home>.btn-filter{
    position: absolute;
    left: 150px;
    top: 8px;
    z-index: 2;
}

.btn-filter .btn{
    cursor: pointer;
}

.btn-filter .btn.active{
    background: var(--global-color-accent);
    color: var(--global-color-primary);
}

.fenetex-slider{
    display: none;
}

.showcase-slider .swiper-wrapper{
    right: -150px;
}

.showcase-slider .swiper-control{
    position: absolute;
    top: 25px;
    right: 10px;
}

.showcase-slider .swiper-control .swiper-button-prev{
    left: var(--swiper-navigation-sides-offset,-110px);
}

.swiper-button-next, .swiper-button-prev{
    border: 1.5px solid var(--global-color-accent);
    width: 40px!important;
    height: 40px!important;
}

/*.swiper{
    overflow: visible!important;
}*/

.showcase-slider .swiper-slide img{
    border-radius: 30px;
    max-height: 570px;
    width: 100%;
    object-fit: cover;
}

.swiper-button-next:after, .swiper-button-prev:after{
    font-size: 18px!important;
    color: var(--global-color-accent);;
}

.why-fenetex{
    background: var(--global-color-primary);
}

.why-fenetex .section-title-dark p{
    color: #fff;
    max-width: 750px;
    margin: 0 auto;
}

.why-fenetex .section-title-dark p span{
    color: var(--global-color-accent);
    font-weight: 600;
}

.wf-col{
    padding: 30px 20px;
    text-align: center;
}

.wf-col h3{
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-top: 20px;
}

.wf-col p{
    color: #FFFFFF7A;
    font-size: 11px;
}

.above-footer-row{
    background-color: #fff;
    background-image: linear-gradient(90deg, var(--global-color-accent) 50%, var(--global-color-secondary) 49%);
}

.get-in-touch{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px auto 15px;
}

.get-touch-text{
    padding: 0px 15px;
}

.get-in-touch h3{
    color: var(--global-color-primary);
    font-size: 18px;
    font-weight: 600;
}

.get-in-touch p{
    color: var(--global-color-primary);
    font-size: 13px;
    font-weight: 400;
}

.see-our-product{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px auto 15px 25px;
}

.see-product-text{
    padding: 0px 15px;
}

.see-product-text h3{
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.see-product-text p{
    color: #fff;
    font-size: 13px;
    font-weight: 400;
}

footer{
    background: var(--global-color-primary);
}

.footer-main{
    padding: 60px 0px;
}

.footer-main.dealer-footer{
    padding: 0px 0px;
    overflow: hidden;
    min-height: 250px;
}

.dealer-footer-contact{
    margin-top: -250px;
    padding-top: 30px;
}

footer ul{
    padding-left: 0px;
}

footer ul li{
    list-style-type: none;
}

footer h3{
    color: var(--global-color-white);
    font-size: 18px;
    font-weight: 500;
}

footer h3 a{
    color: var(--global-color-white);
}

.footer-logo{
    text-align: right;
}

.footer-logo h2{
    color: #A6A9AF;
    font-size: 18px;
    font-weight: 400;
    margin: 15px 0px;
}

.footer-logo h2 a{
    color: #A6A9AF;
}

.footer-logo h3{
    color: #A6A9AF;
    font-size: 15px;
    font-weight: 400;
    margin: 15px 0px;
}

.footer-logo h3 a{
    color: #A6A9AF;
}

.footer-social{
    text-align: right;
}

.footer-social a{
    background: #353D46;
    margin-left: 20px;
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
    color: #fff;
    font-size: 20px;
}


.city-product-card{
    background: var(--global-color-white);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.cp-content{
    padding: 15px;
}

.cp-content h4{
    font-size: 18px;
}

.city-video-sec{
    background: #00101C;
    padding: 80px 0px 0px;
}

.city-video-sec .youtube-wrap iframe{
    position: relative;
    top: 7px;
}

.commitment-sec{
    background: var(--global-color-primary);
    padding: 80px 0px;
}

.commit-grid{
    padding: 0px 40px;
}

.commit-grid img{
    border-radius: 15px;
}

.commit-content{
    color: #fff;
    padding: 20px 0px;
}

.customization-sec{
    padding: 80px 0px;
}

.customization-sec p{
    max-width: 400px;
}

.swatch-imgs{
    margin-top: 45px;
}

.swatch-imgs img{
    max-width: 60px;
    margin-right: 20px;
}

.customization-sec .section-title-light h3 {
    font-size: 30px;
    line-height: 39px;
}

.banner-form.city-form{
    padding: 30px;
}

/* .home-banner-section {
    position: relative;
    height: 100vh;
    max-height: 700px;
} */

.home-banner-section .banner-bg{
    height: 100%;
    overflow: hidden;
    position: absolute;
    width: 100%;
}

.home-banner-section .banner-bg .banner-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*.home-banner-section .banner-content .banner-title{
        background: #ffffffd1;
    color: var(--global-color-primary);
    padding: 40px 40px 40px 0px;
    position: relative;
}

.home-banner-section .banner-content .banner-title:before{
    position: absolute;
    content: '';
    top: 0;
    left: -80%;
    width: 80%;
    height: 100%;
    background: #ffffffd1;
}
*/

.home-banner-overlay{
    width: 59%;
    max-width: 59%;
    transform: skew(10deg);
    background-color: #052237BD;
    height: 100vh;
    position: absolute;
    top: 0;
    left: -90px;
}


.home-about .about-left .section-title-light h2.heading{
    font-size: 35px;
    line-height: 39px;
}

.home-about .about-left{
    padding: 0px 30px 0px 0px;
}

.lifetime-warranty{
    background: #00101C;
    /* margin-top: 150px; */
}

.lifetime-warranty img{
    max-width: 240px;
    margin-top: -180px;
}

.lifetime-warranty h2{
    color: var(--global-color-white);
    font-size: 28px;
    font-weight: 500;
}

.lifetime-warranty h2 span{
    font-weight: 700;
    color: var(--global-color-accent);
}

.video-wrapper{
    padding-bottom: 56.25%;
    position: relative;
}

.video-wrapper iframe{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 0;
    background-color: #000;
}

.video-wrapper img{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 0;
    background-color: #000;
    object-fit: cover;
    z-index: 2;
    cursor: pointer;
}

.request-estimate{
    padding: 45px 0px;
    background: var(--global-color-secondary);
}

.request-estimate h3{
    color: var(--global-color-white);
    font-size: 30px;
    font-weight: 600;
}

.unmatched-excellence{
    background: #F3F3F3;
}

.unmatch-list{
    width: 28%;
    float: left;
}

.unmatch-slider{
    width: 72%;
    float: left;
}

.unmatch-list{
    margin-top: 80px;
    background: var(--global-color-white);
    border-radius: 5px;
    border: 1px solid rgba(9,9,9,.1);
}

.unmatch-list ul{
    padding: 0px 25px;

}

.unmatch-list li{
    list-style-type: none;
    font-size: 12px;
    font-weight: 400;
    border-bottom: 1px solid #87878740;
    padding: 15px 10px;
}

.unmatch-list li:last-child{
    border: none;
}

.unmatch-slider .pricing-list{
    background: var(--global-color-white);
    border-radius: 8px;
    border: 1px solid rgba(9,9,9,.1);
    color: var(--global-color-text);
}

.unmatch-slider .pricing-list.list-1{
    background: var(--global-color-primary);
}

.unmatch-slider .pricing-list.list-2{
    background: #00101C;
}

.unmatch-slider .pricing-list .pl-title{
    height: 80px;
    padding: 20px;
}

.unmatch-slider .pricing-list ul{
    padding-left: 0px;
    padding: 0px 10px;
}

.unmatch-slider .pricing-list li{
    list-style-type: none;
    font-size: 12px;
    font-weight: 400;
    border-bottom: 1px solid #87878740;
    padding: 15px 5px;
    min-height: 49px;
    position: relative;
    text-align: center;
}

.unmatch-slider .pricing-list li:last-child{
    border: none;
}

.pricing-list li img{
    width: 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50% , -50%);
    left: 50%;
}

.haq-section{
    background: var(--global-color-primary);
}

.haq-left{
    padding: 50px 0px 30px 0px;
}

.haq-left h2{
    color: var(--global-color-accent);
    font-size: 35px;
    font-weight: 500;
    line-height: 45px;
}

.haq-left p{
    color: var(--global-color-white);
    font-size: 18px;
    font-weight: 400;
    line-height: 1.7;
    margin-top: 30px;
}

.haq-right{
    padding: 30px 30px 30px 30px;
    background-color: #FFFFFF36;
}

.haq-right h3{
    color: #fff;
    font-size: 26px;
    margin: 0 0 20px 0;
}

.haq-right .form-group{
    margin-bottom: 20px;
}

.haq-right .form-group input , .haq-right .form-group select , .haq-right .form-group textarea{
    background: rgb(255 255 255 / 50%) !important;
    font-size: 11px;
    color: #fff !important;
    padding: 6px 10px;
    width: 100%;
    line-height: 1.3;
    border: none;
    border-radius: 2px;
}

.contact-option{
    color: #000000 !important;
}

.haq-right .form-group input , .haq-right .form-group select{
    height: 38px;
}

.haq-right .form-group .iti{
    width: 100%;
}

.haq-right .form-group input::placeholder , .haq-right .form-group select::placeholder , .haq-right .form-group textarea::placeholder{
    color: var(--global-color-white);
}

.product-why-sec{
    background: var(--global-color-accent);
    padding-top: 10px;
}

.product-why-sec .wf-col{
    padding: 30px 20px;
    text-align: center;
}

.product-why-sec .wf-col h3{
    font-size: 15px;
    font-weight: 500;
    color: var(--global-color-primary);
    margin-top: 20px;
}

.product-why-sec .wf-col p{
    color: var(--global-color-primary);
    font-size: 11px;
}

.explore-tech-sol{
    background: #F3F3F3;
}

.ets-imgs{
    display: flex;
}

.ets-img{
    padding: 10px 15px;
    border-right: 1px solid var(--global-color-text);
    flex-grow: 1;
    flex-basis: 0;
}

.ets-img img{
    max-width: 120px;
    max-height: 30px;
}

.project-sec{
    background: var(--global-color-primary);
}

.project-slider{
    padding-top: 0px!important;
}

.project-slider .swiper-slide {
  width: 60%!important;
}

.project-slider .swiper-slide img {
    border-radius: 0px;
}

.project-slider .swiper-control{
    position: absolute;
    top: 30px;
    left: 50%;
}

.project-slider .swiper-button-prev{
    left: var(--swiper-navigation-sides-offset,-50px);
}

.project-slider .swiper-button-next{
    right: var(--swiper-navigation-sides-offset,-50px);
}

.project-slider .swiper-button-prev, .project-slider .swiper-button-next{
    z-index: 1!important;
}

.single-product-banner{
    height: 83vh;
    overflow: hidden;
    position: relative;
    background: #F4F4F4;;
}

.single-product-banner .banner-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
}

.single-product-banner .banner-img img{
    max-height: 83vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.single-product-banner .banner-content{
    padding: 30px 50px;
}

.single-product-banner .banner-content h1{
    font-size: 73px;
    font-weight: 400;
    line-height: 1;
    color: var(--global-color-primary);
}

.single-product-banner .banner-content p{
    font-size: 15px;
    font-weight: 300;
    line-height: 1.7;
    margin-bottom: 20px;
    margin-top: 20px;
}

.single-product-banner .btn-dark-outline{
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 500;
    border-radius: 90px 90px 90px 90px;
    padding: 7.5px 23px;
    color: var(--global-color-darkgray);
    background-color: #F9CB3100;
    border: 1px solid var(--global-color-darkgray);
}

.product-page-nav{
    margin-top: -52px;
    position: relative;
}


.product-page-nav .navigation{
    padding: 17px 20px;
    background-color: var(--global-color-accent);
    border-radius: 20px 20px 0px 0px;
}

.product-page-nav .navigation ul{
    display: flex;
    justify-content: space-around;
    margin: 0px;
    width: 90%;
    margin: 0 auto;
}

.product-page-nav .navigation ul li{
    list-style-type: none;
}

.product-page-nav .navigation ul li a{
    text-transform: uppercase;
    color: var(--global-color-darkgray);
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
}

.screen-features{
    padding: 20px 0px;
}

.screen-features h3{
    font-size: 13px;
    font-weight: 500;
    color: var(--global-color-text);
    margin: 15px 0px;
}

.features-sec{
    background: var(--global-color-primary);
}

#feature_accordion .accordion-item{
    background: transparent;
    border-color: var(--global-color-accent);
}

#feature_accordion .accordion-item button{
    background: transparent;
    color: var(--global-color-white);
    outline: none!important;
    padding: 10px 0px;
    box-shadow: none;
}

#feature_accordion .accordion-item .accordion-body{
    padding: 10px 0px;
    color: var(--global-color-white);
}

#feature_accordion .accordion-button::after{
    content: "+";
    font-size: 25px;
    font-weight: 700;
}

.accordion-button::after{
    content: "+";
    font-size: 25px;
    font-weight: 700;
    background-image: unset;
}
.accordion-button:not(.collapsed)::after{
    content: "-";
    font-size: 25px;
    transform:none;
    font-weight: 700;
    background-image: unset;
}

.light-accordian .accordion-button::after{
    color: var(--global-color-secondary);
}

.light-accordian .accordion-header{
    font-size: 16px !important;
    font-weight: 700 !important;
    color: var(--global-color-text) !important;
}

#feature_accordion .accordion-button:not(.collapsed)::after{
    content: "-";
    font-size: 25px;
    transform:none;
    font-weight: 500;
}

.features-sec .img-box{
    width: 75%;
    margin: 0 auto;
}

.features-sec{
    background: var(--global-color-primary);
}

#total_accordion .accordion-item{
    background: transparent;
    border-color: var(--global-color-secondary);
}

#total_accordion .accordion-item button{
    background: transparent;
    color: var(--global-color-text);
    font-weight: 500;
    outline: none!important;
    padding: 10px 0px;
    box-shadow: none;
}

#total_accordion .accordion-button::after{
    color: var(--global-color-secondary);
}

#total_accordion .accordion-item:last-child{
    border-color: var(--global-color-secondary) !important;
}

#total_accordion .accordion-item .accordion-body{
    padding: 10px 0px;
}

.product-options{
    background: #F3F3F3;
}

#accordion-style-two .accordion-item{
    border: none;
}

#accordion-style-two .accordion-item button{
    background: transparent;
    color: var(--global-color-primary);
    outline: none!important;
    box-shadow: none;
    border: 1px solid #ddd;
    border-left: 2px solid var(--global-color-secondary);
    font-size: 13px;
    font-weight: 600;
}

#accordion-style-two .accordion-item .accordion-body{
    color: var(--global-color-text);
    font-size: 13px;
}

#accordion-style-two .accordion-body li{
    margin-bottom: 10px;
}
.navbar-toggler-icon{
    background-image: unset !important;
    color: #fff;
    height:20px;
}

/* @media (max-width: 425px){
    .unmatch-list{
        width: 60%;
        float: left;
    }

    .unmatch-slider{
        width: 40%;
        float: left;
    }
} */

@media (min-width: 1440px){
    .single-product-banner{
        height: 82vh;
    }

    .single-product-banner .banner-content{
        padding: 50px 50px
    }
}

@media (min-width: 1600px){
    .single-product-banner{
        height: 55vh;
    }

    .single-product-banner .banner-content{
        padding: 60px 50px
    }
}


@media (max-width: 1024px){
    .home-about .about-left .section-title-light h2.heading {
        font-size: 28px;
        line-height: 34px;
    }

    .customization-sec .col-lg-4 img{
        max-width: 350px;
        width: 100%;
    }

    .customization-sec .section-title-light h3{
        font-size: 28px;
        line-height: 34px;
    }
}


@media (min-width: 992px){
    .nav-item.dropdown:hover .dropdown-menu{
        display: block;
        top: 25px;
    }
}

@media (max-width: 991px){

    .nav-item.dropdown{
        align-items: center;
    }

    .nav-item.dropdown .nav-link{
        margin-right: 5px;
    }

    .navbar-nav .nav-link a{
        margin-right: 7px;
    }

    .nav-item.dropdown .nav-link span.dropdown-toggle::after{
        top: 5px;
    }

    /* .nav-item span.dropdown-toggle:hover + .dropdown-menu{
        display: block;
        flex: 1 1 100%;
    } */

    .nav-item .nav-link.dropdown-toggle::after{
        display: none;
    }
}