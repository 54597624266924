@import './vars.css';

.magforce-title{
    font-size: 30px;
}

.magforce-section .img-div{
    text-align: right;
}

.about{
    margin: 80px auto;
}

.about-left{
    padding-left: 30px;
    padding-top: 30px;
}

.about-left .section-title-light h3{
    font-size: 25px;
    line-height: 35px;
}

.about h4{
    color: var(--global-color-primary);
    font-family: "Poppins", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
}

.about h3{
    color: var(--global-color-primary);
    font-family: "Poppins", Sans-serif;
    font-size: 35px;
    font-weight: 300;
    /* line-height: 45px; */
}


.about-section-title-light h2 {
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    font-weight: 700;
    line-height: 49px;
    color: #fff;
}
.about-section-title-light h2 span {
    color: var(--global-color-accent);
}
.accent-btn {
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-transform: none;
    fill: #2e2e2e;
    color: #2e2e2e;
    background-color: #f9cb31;
    border-radius: 90px 90px 90px 90px;
}
.yellow-text{
    color: #f9cb31;
}

.technologies-explore{
    background-color: #f9cb31;
    padding: 50px 0px 50px 0px;
}
.technologies-explore h2{
    color: #062A44;
    font-family: "Poppins", Sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.technologies-explore .content-col {
    max-width: 640px;
    margin: auto;
}
.content-col p {
    text-align: center;
    color: #062A44;
    font-family: "Poppins", Sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.magforce-section{
    background-color: transparent;
    background-image: radial-gradient(at center center, #073556 57%, var(--global-color-primary) 100%);
}
.magforce-section .row {
    padding: 50px 0;
}
.magforce-section .features-warranty-section {
    background-color: var(--global-color-primary);
    box-shadow: 20px 32px 60px 0px rgba(19.99999999999998, 58.99999999999993, 81, 0.27);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    border-radius: 10px 10px 10px 10px;
    padding: 30px 20px 30px 20px;
    max-width: 900px;
    margin: auto;
}
.magnet-features h2 {
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 35px;
    font-weight: 500;
    line-height: 40px;
}
.magnet-features .feature-block {
    padding: 50px;
}
.feature-block h3 {
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-top: 20px;
}
.features-slider-heading {
    padding: 0 !important;
}

.features-slider-heading .swiper-button-prev{
    border:none !important;
    margin-bottom: 0 !important;
    height: fit-content !important;
    padding-right:80px;
}
.features-slider-heading .swiper-button-prev::after{
    color: #fff;
    font-size: 8px !important;
}

.features-slider-heading .swiper-button-next{
    border:none !important;
    margin-bottom: 0 !important;
    height: fit-content !important;
    padding-left:80px;
}
.features-slider-heading .swiper-button-next::after{
    color: #fff;
    font-size: 8px !important;
}


.features-warranty-section p {
    margin: 0;
    color: #FFFFFF80 !important;
}
.features-warranty-section img {
    margin: -90px 0px -90px 0px;
}
.magforce-section .magforce-title{
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 300;
    line-height: 35px;
    margin-bottom: 30px;
}
.magforce-section .magsafe-img{
    margin: 30px 0px;
}
.magforce-section p{
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 13px;
    font-weight: 400;
}
.features-slider-heading h2{
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 3px;
    margin-bottom: 20px !important;
}

.features-item-slider {
    padding-right: 0px;
}

.features-item-slider .swiper-slide {
    height: 300px;
}

.features-item-slider .swiper-wrapper {
    padding-bottom: 30px;
}
.features-item-slider .swiper-pagination {
    bottom: 0px;
}
.features-item-slider .carousel-bg {
    height: 100%;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
}

.features-item-slider .swiper-slide {
    background: #fff;
    border-radius: 10px;
    margin: 5px 10px !important;
}
.carousel-content {
    position: absolute;
    top: 0;
    width: 50%;
    padding: 20px;
}
.carousel-content h4 {
    font-size: 17px;
    color: var(--global-color-text);
    font-weight: 700;
}
.carousel-content p {
    color: var(--global-color-text);
    font-family: "Poppins", Sans-serif;
    font-size: 13px;
    font-weight: 400;
}
.onetrack-section {
    background: #F3F3F3;
    padding: 100px 0px;
}
.onetrack-section h2 {
    color: var(--global-color-text);
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 20px;
}
.onetrack-img {
    margin-top: 20px;
    margin-bottom: 20px;
}
.features-warranty-section {
    margin-top: 100px;
    margin-bottom: 100px;
    background: var(--global-color-primary);
    box-shadow: 20px 32px 60px 0px rgba(19.99999999999998, 58.99999999999993, 81, 0.27);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 15px 15px 15px 15px;
    border-radius: 10px 10px 10px 10px;
}
.onetrack-section .features-slider-heading h2{
    color: var(--global-color-text);
    font-family: "Poppins", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 3px;
}
.common-features {
    padding: 50px 0px;
}
.common-features h2{
    color: var(--global-color-text);
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 300;
}
.common-features-text {
    width: 50%;
    margin: auto;
}
.common-features-text p{
    color: #000;
    margin-bottom: 20px;
}
.features-list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}
.features-list button {
    font-family: "Poppins", Sans-serif;
    font-size: 13px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    text-align: left;
    align-items: center;
    border-radius: 20px 20px 20px 20px;
    width: 100% !important;
    padding: 5px 5px 5px 5px;
    max-width: 240px;
}
.features-list button span{
    padding: 0px 15px 0px 10px;
    line-height: 15px;
}
.features-list .modal-content{
    background-color: #ffffffe0;
    border-radius: 25px 25px 25px 25px;
    box-shadow: 2px 8px 23px 3px rgba(0,0,0,0.2);
}
.features-list .modal .modal-dialog .modal-header, .features-list .modal .modal-dialog .modal-body {
    padding: 0;
}
.features-list .modal-body h3{
    color: var(--global-color-primary);
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 20px;
}
.features-list .modal-body p{
    color: var(--global-color-text);
    font-family: "Poppins", Sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.testimonial-slider .swiper-button-prev,.testimonial-slider .swiper-button-next{
    border:unset !important;
    height:20px !important;
    width:20px !important;
    background-color: var(--global-color-white);
}
.testimonial-slider .swiper-button-prev::after,.testimonial-slider .swiper-button-next::after{
    font-size: 14px !important;
    color: var(--global-color-text) !important;
    font-weight: 900 !important;
}
.testimonial-slider{
    position: relative;
}
.testimonial-slider .slider-btn{
    padding: 0 10px !important;
    position: absolute !important;
    z-index: 1;
    top: 55%;
    display: flex;
    justify-content: space-between;
    width: 100%;
}